import React, { useState } from "react"
import CreateSubscription from "../components/MyPage/CreateSubscription"

const Subscription = () => {
  const props = {
    unit: process.env.GATSBY_APP_ORDERLINE_UNIT,
    priceLevel: process.env.GATSBY_APP_ORDER_PRICELEVEL,
    name: process.env.GATSBY_APP_ORDER_NAME_HEF,
    orderLine: process.env.GATSBY_APP_ORDERLINE_HEF_SUB,
    typeMembership: "hefinn",
    source: "MinSide",
    productNumber: "HEF",
  }

  return <CreateSubscription {...props} />
}

export default Subscription
